/*================================================================
  GENERAL SCRIPTS
================================================================*/
//GENERAL VARIABLES
var winPos = $(window).scrollTop();
var windowHeight = window.innerHeight;
var windowWidth = $(window).width();

// detects if it's touch device or not
function is_touch_device() {
	return (('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) ||
  (navigator.msMaxTouchPoints > 0));
}
//jQuery function for fiting images
// (function($) {
//   $.fn.fitImage = function() {
//     var _ = this;
//     var height, width, parHeight, parWidth,src;
//     src = _.attr("src");
//     parWidth = _.parent().innerWidth();
//     parHeight = _.parent().innerHeight();
//     width = _[0].naturalWidth;
//     height = _[0].naturalHeight;
//     if(width !== 0 && height !== 0){
//     } else{
//       var tmpImg = new Image();
//       tmpImg.onload = function() {
//       height = tmpImg.height;
//       width = tmpImg.width;
//     };
//     tmpImg.src = _.attr('src');
//     }
//     if (width/height <= parWidth/parHeight) {
//       _.height("auto");
//       _.width("100%");
//       _.css("visibility","visible");
//     } else{
//      _.width("auto");
//      _.height("100%");
//      _.css("visibility","visible");
//     }
//   };
// })(jQuery);

//jQuery function for containing images
// (function($) {
//   $.fn.containImage = function() {
//     var _ = this;
//     var height, width, parHeight, parWidth,src;
//     src = _.attr("src");
//     parWidth = _.parent().innerWidth();
//     parHeight = _.parent().innerHeight();
//     width = _[0].naturalWidth;
//     height = _[0].naturalHeight;
//     if(width !== 0 && height !== 0){
//     } else{
//       var tmpImg = new Image();
//       tmpImg.onload = function() {
//       height = tmpImg.height;
//       width = tmpImg.width;
//     };
//     tmpImg.src = _.attr('src');
//     }
//     if (width/height >= parWidth/parHeight) {
//       _.height("auto");
//       _.width("100%");
//       _.css("visibility","visible");
//     } else{
//      _.width("auto");
//      _.height("100%");
//      _.css("visibility","visible");
//     }
//   };
// })(jQuery);

$(document).ready(function(){
	//****************** for making pseudo-elements work on ios
	$('body *').on('touchstart', function (){});

	//***************** detects if it's touch device or not and add class
	if (!is_touch_device()) {
		$("body").addClass("no-touch");
		console.log('touch');
	} else{
		$("body").addClass("touch");
	}

	//********************** tgiggering dropdowns
	// for ios
	$('.touch .dropdown-toggle').on('touchstart', function () {
		$(this).parent().toggleClass('open');
  });

	$('.touch .dropdown').on('touchstart', function (e) {
		e.stopPropagation();
	});
	// for ios
	$('.touch').on('touchstart', function () {
		$('.dropdown').removeClass('open');
	});

	// for toggling nav in mobile
	$('.mainNav-toggle').click(function(){
		var nav = $(this).parent().find('.mainNav');
		$(this).toggleClass('active');
		nav.toggleClass('open');
	});
	// var servicesItem = $('.dropdown').parent('.main-nav-item'),
	// 		servicesItemPrev = servicesItem.prev(),
	// 		servicesItemNext = servicesItem.next();
	// if (servicesItemPrev.hasClass('active')) {
	// 	servicesItem.addClass('left');
	// }
	// if (servicesItemNext.hasClass('active')) {
	// 	servicesItem.addClass('right');
	// }
	//
	// $('.navbar').on('touchstart click', function(){
	// 	$(this).removeClass('open');
	// 	$('body').removeClass('no-scroll');
	// });

	// $('.navbar-content').on('touchstart click', function(e){
	// 	e.stopPropagation();
	// });

	//****************************** generate lightbox
	// $('.gallery').each(function() {
	// 	var content = $(this).html();
	// 	content = content.replace(/data-for/g, "id");
	// 	$(this).after('<div class="lightbox-wrapper"><ul class="lightbox">' + content + '</ul></div>');
	// });

  //triggering lightBox
	// $('.gallery li').click(function(){
  //   var whereToGo = $(this).attr("data-for");
  //   var goToIndex = $("#"+whereToGo).index();
  //   winPos = $(window).scrollTop();
  //   $('body').addClass('no-scroll').css('top', -winPos + 'px');
  //   $(this).parent('.gallery').next('.lightbox-wrapper').children('.lightbox').slick('slickGoTo', goToIndex, true);
  //   $(this).parent('.gallery').next('.lightbox-wrapper').addClass('open');
  // });

  //lightBox close
	// $('.lightbox-wrapper').click(function(){
  //   $(this).removeClass('open');
  //   $('body').removeClass('no-scroll').css('top', '');
  //   $(window).scrollTop(winPos);
  // });

	// triggering lightbox slider
	// if (!$('.lightbox').hasClass("slick-initialized")) {
  //   $('.lightbox').slick({
	// 		infinite: false,
	// 	});
	// }

	// var space = 100;
  // if ($(window).width() < 767) {
  //   space = 30;
  // }
  // $('.lightbox li').height(windowHeight - space);
	// $('.lightbox').width($(window).width() - space);

	// prevent Propagation
	// $('.lightbox img, .lightbox button').click(function(e){
  //   e.stopPropagation();
  // });


});

$(window).resize(function() {
  //if width has been actually changed(not scroll on ios)
	if ($(window).width() != windowWidth) {
    windowWidth = $(window).width();
    // code goes here
  }
  // if resize code can be triggered manually or it should run on iOS scroll too, the code goes here
	// if (!$('.lightbox').hasClass("slick-initialized")) {
  //   $('.lightbox').slick({
	// 		infinite: false,
	// 	});
	// }
	//
	// var space = 100;
	// windowHeight = window.innerHeight;
  // if ($(window).width() < 767) {
  //   space = 30;
  // }
  // $('.lightbox li').height(windowHeight - space);
	// $('.lightbox').width($(window).width() - space);
});

//preloder till window loads
$(window).load(function() {
	//setTimeout(function(){ // if we have typekit fonts or so on
	//$('#preloader').removeAttr('style');
	//$('body').removeClass('no-scroll');
	//}, 500);
});
