// input and textarea elements behavior on blur
(function($) {
  $.fn.inputBlur = function() {
    var _ = this,
		    trimText = $.trim(_.val());
		if(trimText.length === 0){
      _.removeClass("filled");
			if (_.hasClass('required')) {
				_.addClass('missed');
			}
    }
    else{
      _.addClass("filled").removeClass('missed');
    }
  };
})(jQuery);

// checking presence for @ and . and so on
(function($) {
  $.fn.mailChecker = function() {
    var _ = this;
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-za-z\-0-9]+\.)+[a-za-z]{2,}))$/;
		if (! re.test(_.val())){
		  _.addClass("missed").removeClass('filled');
	    return false;
	  } else {
	    _.removeClass("missed").addClass('filled');
	    return true;
	  }
  };
})(jQuery);

// form client side validation, make submit button disabled till all required fields are filled
(function($) {
  $.fn.validate = function() {
    var _ = $(this),
        submitButton = _.parents('form').find('button[type=submit]'),

        requiredTextFieldes = _.parents('form').find(".text-field.required").filter(function() {
          //return this.value === "";
					if (!$(this).hasClass('filled')) {
            return $(this);
          }
        }),
        totalRTF = requiredTextFieldes.length,

        emailInputs = _.parents('form').find('input[type=email].required').filter(function() {
          if (!$(this).hasClass('filled')) {
            return $(this);
          }
        }),
				totalEI = emailInputs.length;
				console.log(totalEI, totalRTF);

    if( totalRTF || totalEI ) {
      submitButton.addClass('disabled');
			console.log('yes');
    } else {
      submitButton.removeClass('disabled');
			console.log('no');
    }
  };
})(jQuery);

// reset form values to null
function clear_form(formElement) {
  formElement.find('.text-field').val('').removeClass('filled missed');
  formElement.find('input[type=checkbox], input[type=radio]').prop('checked', false);
}

$( document ).ready(function () {
  // behaviour for text fields
	$("form").on('blur', ".text-field.required", function(){
		$(this).inputBlur();
    //$(this).validate();
	});

	$("form").on('blur', "input[type='email'].required", function(){
		$(this).mailChecker();
	});

  // enabling submit button when all text-inputs and dropdowns are filled
  $("form").on('keyup', ".text-field.required", function(){
    $(this).inputBlur();
    $(this).validate();
  });

  $("form").on('keyup', "input[type='email'].required", function(){
		$(this).mailChecker();
    $(this).validate();
	});

  //Using AJAX FORM plugin
  // $('form').ajaxForm({
  //   datatype: "json",
  //   beforeSubmit: function() {
	//
  //   },
  //   success: function () {
  //     clear_form($(this));
  //     //console.log('success');
  //     //$('.attention').text('Ваше сообщение успешно отправлено.');
  //   },
  //   error:  function() {
  //     //clear_form($(this));
  //     //$('.attention').text('Сервер временно недоступен. Попробуйте позднее.');
  //   }
  // });

});
